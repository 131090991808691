<template>
    <Form ref="addForm" :model="addFormData" :rules="addFormValitor" :label-width="90" :label-colon="true">
            <FormItem label="所属上级">
              <Breadcrumb>
                <BreadcrumbItem
                  v-for="(item, index) in breadcrumbList"
                  :key="index"
                  >{{ item }}</BreadcrumbItem
                >
              </Breadcrumb>
            </FormItem>
             <FormItem label="区域名称" prop="region">
                 <Input :maxlength="20" v-model.trim="addFormData.region"></Input>
             </FormItem>
             <FormItem label="区域编码" v-if="addFormData.code">
                 <Input :maxlength="20" v-model.trim="addFormData.code" disabled></Input>
             </FormItem>
             <FormItem label="是否小区" prop="type" v-if="!addChangeStatus && level < 2">
                <RadioGroup v-model="addFormData.type">
                <Radio label="1">是</Radio>
                <Radio label="2">否</Radio>
            </RadioGroup>
             </FormItem>
              <FormItem label="排序">
                  <InputNumber :min="1" :max='999999999' v-model="addFormData.seq"></InputNumber>
             </FormItem>
              <FormItem label="备注">
                 <Input :maxlength="400" type="textarea" show-word-limit :autosize="{minRows: 2,maxRows: 5}" v-model.trim="addFormData.remark"></Input>
             </FormItem>
          </Form>
</template>

<script>
   export default {
       props: ['codeId','infoId','addChangeStatus','addFormData','addFormValitor','level','orgId','regionId','regionType','roadOrgId'],
       data() {
            return {
              breadcrumbList: [],
           }
        },
        methods: {
            save() {
                this.$refs.addForm.validate(status => {
                    if(status) {
                        if(this.addChangeStatus) {
                            this.$post('/gx/pc/region/updateRegion',{
                            level: this.level,
                            id: this.infoId,
                            name: this.addFormData.region,
                            regionType: this.regionType,
                            remark: this.addFormData.remark,
                            seq: this.addFormData.seq,
                            type: this.addFormData.type
                        }).then(res => {
                            if(res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "修改成功",
                                    duration: 1
                                });
                                this.$emit('addStatusFn',false);
                                this.$emit('resetGetList')
                            }else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                    duration: 1
                                })
                            }
                        })
                        }else {
                        this.$post('/gx/pc/region/addRegion',{
                            level: this.level,
                            name: this.addFormData.region,
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: this.orgId,
                            regionCode: this.regionId,
                            regionType: this.regionType,
                            remark: this.addFormData.remark,
                            roadOrgCode: this.roadOrgId,
                            seq: this.addFormData.seq,
                            type: this.addFormData.type
                        }).then(res => {
                            if(res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "新增成功",
                                    duration: 1
                                });
                                this.$emit('addStatusFn',false);
                                this.$emit('resetGetList')
                            }else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                    duration: 1
                                })
                            }
                        })
                    }
                    }
                })
            }
        },
        created() {
            this.$post('/gx/pc/region/getRegionParentNames',{
                code: this.codeId,
                level: this.level,
                regionType: this.regionType
            }).then(res => {
                if(res.code == 200) {
                  console.log(res, 'ljj 200查看区域')
                  this.breadcrumbList = [];
                  if (res.data.communityName) this.breadcrumbList.push(res.data.communityName);
                  if (res.data.roadName) this.breadcrumbList.push(res.data.roadName);
                  if (res.data.regionName) this.breadcrumbList.push(res.data.regionName);
                  if (res.data.buildName) this.breadcrumbList.push(res.data.buildName);

                }else {
                    this.$Message.error({
                        background: true,
                        content: "获取当前区域所属的上级失败"
                    })
                }
            })
        }
    }
</script>

<style scoped lang='less'>

</style>
