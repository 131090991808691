<template>
  <LiefengContent>
    <template v-slot:title>区域管理</template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        treeID="mychild"
        fixedContentID="contentarea"
        :fixedmenu="true"
        @handleData="getList"
        style="width: 200px"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
        :maxlength="20"
        placeholder="按区域名称，编码搜索"
        v-model.trim="selectName"
        style="width: 200px; margin-right: 6px"
        @on-search="search"
      ></Input>
      <Button type="primary" @click="search">搜索</Button>
      <Button type="success" @click="reset">重置</Button>
      <Button type="error" icon="ios-cloud-upload-outline" @click="importExcel">导入</Button>
      <Button type="info" icon="ios-download-outline" @click="exportExcel">导出</Button>
      <Button type="warning" icon="md-add" @click="addModal" style="margin-right: 10px">添加下级区域</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 新增 -->
      <LiefengModal :title="modalTitle" width="500px" :value="addStatus" @input="addStatusFn">
        <template v-slot:contentarea>
          <AddRegion
            ref="addRegion"
            @hadlePageSize="hadlePageSize"
            @addStatusFn="addStatusFn"
            :addChangeStatus="addChangeStatus"
            @resetGetList="resetGetList"
            v-if="addStatus"
            :infoId="infoId"
            :codeId="codeId"
            :level="level"
            :orgId="orgId"
            :regionId="regionId"
            :roadOrgId="roadOrgId"
            :regionType="regionType"
            :addFormValitor="addFormValitor"
            :addFormData="addFormData"
          ></AddRegion>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" style="margin-right: 10px" @click="addStatusFn(false)">取消</Button>
          <Button type="primary" @click="addModalSave">保存</Button>
        </template>
      </LiefengModal>
      <!-- 导入excel弹窗 -->
      <LiefengModal title="导入区域数据" width="40%" :value="excelStatus" @input="excelModalData">
        <template v-slot:contentarea>
          <Form :model="formValidate" :rules="ruleForm" :label-colon="true" :label-width="100">
            <FormItem prop="marital" label="所属社区">
              <Select
                v-model="formValidate.marital"
                placeholder="请选择社区"
                filterable
                @on-change="selectCommunity"
              >
                <Option
                  v-for="(item, index) in communityList"
                  :key="index"
                  :value="item.code"
                >{{ item.fullName }}</Option>
              </Select>
            </FormItem>
          </Form>
          <Divider orientation="left">数据上传</Divider>
          <Upload
            ref="excelUpload"
            type="drag"
            :action="action"
            name="file"
            :show-upload-list="false"
            :before-upload="beforeExcelUpload"
            :on-success="myExcel"
            :data="uploadData"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
            :headers="headers"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
          <!-- <div v-html="errorContent"></div> -->
          <div style="text-align: center; margin: 20px 0">
            <Button type="primary" style="margin-right: 20px">
              <a href="./excelfiles/region.xlsx" download="区域导入模板.xlsx" style="color: #fff">模板下载</a>
            </Button>
            <Button type="info" @click="submitExcel">确认导入</Button>
          </div>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/regionmanage')
import LiefengTree from "@/components/LiefengTree";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import AddRegion from "@/views/region/childrens/addRegion";
import qs from "qs";

export default {
  data() {
    return {
      //导入参数
      uploadData: {},
      errorContent: "",
      action: "",
      ruleForm: {
        marital: [{ required: true, message: "请选择社区", trigger: "change" }]
      },
      formValidate: {},
      //excel导入弹窗
      excelStatus: false,
      //excel导入弹窗  社区数据
      communityList: [],
      //excel文件数据
      excelFile: null,
      selectName: "", // 搜索名称或编码
      talbeColumns: [
        {
          type: "selection",
          width: 80,
          align: "center"
        },
        {
          title: "上级区域",
          key: "parentRegion",
          align: "center",
          minWidth: 150
        },
        {
          title: "当前区域",
          key: "region",
          align: "center",
          minWidth: 150
        },
        {
          title: "编码",
          key: "code",
          align: "center",
          minWidth: 150
        },
        {
          title: "排序",
          key: "seq",
          align: "center",
          width: 150
        },
        {
          title: "操作",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small"
                  },
                  style: {
                    marginRight: "10px"
                  },
                  on: {
                    click: () => {
                      this.modalTitle = "修改下级区域";
                      this.changeModal(params.row.id);
                    }
                  }
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small"
                  },
                  on: {
                    click: () => {
                      this.delRow(params.row.id);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        }
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,
      codeId: "", //菜单树codeId
      level: "", //树的层级 0:社区；1：街巷：2：小区或单独楼栋；3：小区楼栋
      regionType: "", //区域类型 1：小区；2：小区的楼栋；3：单独的楼栋
      orgId: "", //社区id，新增修改时用
      regionId: "", //小区/楼栋id，新增修改时用
      roadOrgId: "", //街巷id，新增修改时用
      //新增
      addStatus: false,
      modalTitle: "",
      addChangeStatus: false, //新增/修改标识，false代表新增
      infoId: "",
      addFormData: {
        seq: 1
      },
      addFormValitor: {
        region: [
          { required: true, message: "请输入区域名称", trigger: "blur" }
        ],
        type: [
          { required: true, message: "请选择是否为小区", trigger: "change" }
        ]
      },
      // zhw添加
      // 获取树节点信息
      selectData: {},
       headers: {openId: window.sessionStorage.getItem('openId'),Authorization:window.sessionStorage.getItem('accessToken')},
    };
  },
  methods: {
    //新增
    addModal() {
      this.modalTitle = "添加下级区域";
      this.addFormData = {
        seq: 1
      };
      this.addStatus = true;
      this.addChangeStatus = false;
    },
    //新增/修改后重新获取菜单树列表
    resetGetList() {
      this.$refs.mychild.getTree(this.orgId);
    },
    //修改excel导入弹窗状态
    excelModalData(status) {
      this.excelStatus = status;
    },
    //导入按钮
    importExcel() {
      this.excelStatus = true;
      this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode
      })
        .then(res => {
          if (res.code == 200 && res.dataList && res.dataList.length > 0) {
            this.communityList = res.dataList;
          } else {
            this.$Message["error"]({
              background: true,
              content: "获取社区数据失败！"
            });
          }
        })
        .catch(err => {
          this.$Message["error"]({
            background: true,
            content: "无法导入数据！"
          });
        });
    },
    //选择社区
    selectCommunity(code) {
      this.uploadData.orgCode = code;
      this.communityList.map((item, index) => {
        if (item.code == code) {
          this.uploadData.orgName = item.fullName;
        }
      });
    },

    //确认导入
    submitExcel() {
      if (this.uploadData.orgCode == undefined) {
        this.$Message.error({
          background: true,
          content: "请选择所属社区"
        });
        return;
      }
      this.$refs.excelUpload.post(this.excelFile);
    },
    //导入excel格式错误
    uploadFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件"
      });
    },
    //excel文件上传成功后执行
    myExcel(res) {
      if (res.code == 200 && res.dataList.length == 0) {
        this.$Message.success({
          background: true,
          content: "上传成功！"
        });
        this.excelStatus = false;
        this.$refs.mychild.getTree(this.orgId);
      } else {
        this.errorContent = "";
        res.dataList.map((item, index) => {
          this.errorContent += `<div style="color:red">第${item.number +
            2}行：${item.errorCode}</div>`;
        });
        this.$Notice.error({
          title: "导入失败",
          desc: this.errorContent,
          duration: 0
        });
      }
    },
    //上传之前
    beforeExcelUpload(file) {
      (this.action =
        window.vue.getProxy()["/gx"].target + "/upload/region/importUsRegion"),
        (this.uploadData.oemCode = parent.vue.oemInfo.oemCode);
      this.excelFile = file;
      return false;
    },
    //删除
    delRow(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除该数据，此操作不可逆，是否继续？</p>",
        onOk: () => {
          this.$post("/gx/pc/region/deleteRegion", {
            id,
            level: this.level,
            regionType: this.regionType
          }).then(res => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "删除成功",
                duration: 1
              });
              this.$refs.mychild.getTree(this.orgId);
            } else {
              this.$Message.error({
                background: true,
                content: res.desc
              });
            }
          });
        }
      });
    },
    //新增
    addStatusFn(status) {
      this.addStatus = status;
    },
    addModalSave() {
      this.$refs.addRegion.save();
    },
    //修改
    changeModal(id) {
      this.infoId = id;
      this.addChangeStatus = true;
      this.$Message.loading({
        duration: 0,
        content: "正在加载数据..."
      });
      this.$post("/gx/pc/region/queryRegionDetail", {
        id,
        level: this.level,
        regionType: this.regionType
      })
        .then(res => {
          if (res.code == 200) {
            this.addFormData = res.data;
            this.addStatus = true;
            this.$Message.destroy();
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {});
    },
    //搜索
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    reset() {
      this.selectName = "";
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    getList(obj) {
      this.selectData = obj;
      this.codeId = "";
      this.level = "0";
      this.regionType = "";

      if (obj.projectCode) {
        //如果存在，则表示是小区或者楼栋
        if (obj.regionType == "1") {
          //regionType== 2，表示小区
          this.level = "2";
        } else if (obj.regionType == "2") {
          //regionType== 2,表示小区楼栋
          this.level = "3";
        } else if (obj.regionType == "3") {
          //regionType== 3,表示单独楼栋
          this.level = "2";
        }
        this.codeId = obj.projectCode;
        this.regionId = obj.projectCode;
      } else if (obj.roadCode) {
        //如果存在，则表示街道
        this.level = "1";
        this.codeId = obj.roadCode;
        this.roadOrgId = obj.roadCode;
      } else {
        //否则，就是社区
        this.level = "0";
        this.codeId = obj.communityCode;
        this.orgId = obj.communityCode;
      }
      if (obj.regionType) {
        this.regionType = obj.regionType;
      }
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    hadlePageSize(obj) {
      this.$post("/gx/pc/region/queryRegionPage", {
        code: this.codeId,
        level: this.level, //树的层级 0:社区；1：街巷：2：小区或单独楼栋；3：小区楼栋
        name: this.selectName,
        page: obj.page,
        pageSize: obj.pageSize,
        regionType: this.regionType //区域类型 1：小区；2：小区的楼栋；3：单独的楼栋
      })
        .then(res => {
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          this.$Message.error({
            background: true,
            content: "获取数据失败"
          });
        });
    },

    // zhw添加代码
    // 导出功能
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>您正在导出${this.selectData.orgName}的数据，是否继续？</p>`,
        onOk: () => {
          this.$Message.loading({
            content: "正在导出数据，请稍等...",
            duration: 0
          });
          this.axios({
            url:
              window.vue.getProxy()["/gx"].target +
              "/upload/region/exportRegion",
            data: qs.stringify({
              orgCode: this.selectData.communityCode
            }),
            method: "POST",
            responseType: "blob",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization":window.sessionStorage.getItem('accessToken')
            }
          }).then(res => {
            console.log("获取的data数据", res.data);
            this.download(res.data, this.selectData.orgName);
          });
        }
      });
    },
    download(data, name) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
      );
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `${name}社区-地址初始化数据`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.$Message.destroy();
    }
  },

  components: {
    LiefengTree,
    LiefengTable,
    LiefengModal,
    LiefengContent,
    AddRegion
  }
};
</script>

<style scoped lang='less'>
</style>
